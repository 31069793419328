import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom"
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import "bootstrap-icons/font/bootstrap-icons.scss";
import "bootstrap/scss/bootstrap.scss";
import "./assets/scss/stylesheet.scss";

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <Router>
    <App />
  </Router>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
